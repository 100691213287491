import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text, BookList, Button, Video } from '../../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="হাদীস শিখি ইউশার সাথে" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  হাদীস শিখি ইউশার সাথে
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/study-materials/',
        name: 'শিক্ষাপোকরণ'
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <p>{`আমাদের সালাফরা কুর্‌আন-হাদীস শুধু পড়ে বুক-সেলফে রেখে দেওয়াকে জ্ঞানই মনে করতেন না। আল-কুর্‌আনের মুখস্থের পাশাপাশি হাদীস মুখস্থেরও প্রতিযোগীতা চালাতেন নিজেদের মাঝে। লাখ-লাখ হাদীস মুখস্থ করে কেউ হয়ে গেলেন ইমাম বুখারী, কেউবা ইমাম আহমাদ রহিমাহুমুল্লাহ। ভবিষ্যৎ প্রজন্মের মাঝে আরেকজন ইমাম ইবনু কাসীর দেখবার লক্ষ্যে আমরা হাতে নিয়েছি এই হাদীস সিরিজ প্রকল্প। ছোট্ট বাবুদের জন্য এই সিরিজের বইগুলোতে বাছাই করা হয়েছে এমন সব হাদীস যা দৈর্ঘ্যে অনেক ছোট, কিন্তু গুরুত্বে ধারণাতীত। ইউশা নামের এক দশ বছরের বাচ্চা ছেলের সাথে গল্পে-গল্পে, ছবি দেখতে দেখতে আমাদের বাচ্চারাও হাদীস শিখবে, মুখস্থ করবে এ-ই আমাদের প্রত্যাশা। আল্লাহ আমাদের নেক উদ্দেশ্য এবং প্রচেষ্টাকে কবুল করুন।`}</p>
      <Text variant="h3" mt={5} mdxType="Text">
  "হাদীস শিখি ইউশার সাথে-১" বইয়ের আরবী ও বাংলা হাদীসের সঠিক উচ্চারণ
      </Text>
      <Video videoSrcURL="https://www.youtube.com/embed/ALAvfpkkTlw" videoTitle="হাদীস শিখি ইউশার সাথে-১ বইয়ের আরবী ও বাংলা হাদীসের সঠিক উচ্চারণ" frameWidth="720px" frameHeight="504px" width="100%" mb={4} mt={4} mdxType="Video" />
      <Text variant="h3" mt={5} mdxType="Text">
  "হাদীস শিখি ইউশার সাথে-২" বইয়ের আরবী ও বাংলা হাদীসের সঠিক উচ্চারণ
      </Text>
      <Video videoSrcURL="https://www.youtube.com/embed/AipErzpJVAQ" videoTitle='"হাদীস শিখি ইউশার সাথে-২" বইয়ের আরবী ও বাংলা হাদীসের সঠিক উচ্চারণ' frameWidth="720px" frameHeight="504px" width="100%" mb={4} mt={4} mdxType="Video" />
      <Box textAlign="center" mb={4} mdxType="Box">
        <a href="/study-materials/learning-hadith-with-yusha/quizes">
  <Button variant="primary" mdxType="Button">বইয়ে দেয়া কুইজের উত্তর</Button>
        </a>
      </Box>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      